<template>
  <div class="home-wrapper">
    <!-- 公用弹窗 -->
    <div v-show="popShow" class="popbox" style="display: block">
      <div class="popwrap"></div>
      <div class="popinner">
        <span
          @click="popShow = false"
          class="popclose"
          style="cursor: pointer; z-index: 9999"
          >X</span
        >
        <div class="popContent">
          <div class="pc-title">
            <h3 v-if="popIndex === 0" style="color: #249eff">招商服务案例</h3>
            <h3 v-if="popIndex === 1" style="color: #ff952e">安商服务案例</h3>
            <h3 v-if="popIndex === 2" style="color: #ff445a">养商服务案例</h3>
            <p style="margin: 0">SUCCESSFUL CASES</p>
          </div>
          <ul>
            <li v-for="(item, index) in popList" :key="index">
              <img :src="item.imgSrc" alt="" />
              <div class="content-right">
                <h3 title="线上科技——井冈山产业招商地图">
                  {{ item.title }}
                </h3>
                <p>
                  {{ item.txt }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 视频弹窗 -->
    <div class="videopop" v-show="show">
      <div class="videwrap"></div>
      <div class="videoinner">
        <span style="cursor: pointer; z-index: 9999" @click="show = false"
          >X</span
        >
        <video
          id="videoId"
          autoplay=""
          loop=""
          preload=""
          x5-video-player-fullscreen="true"
          muted=""
          x-webkit-airplay="true"
          airplay="allow"
          webkit-playsinline="true"
          playsinline="true"
          controls=""
          src="https://rh.2hrh.com/static/file/rhportal/video.mp4"
        ></video>
      </div>
    </div>
    <!-- 背景视频 -->
    <div class="headerbox">
      <video
        id="videobox"
        ref="bgVideo"
        loop=""
        preload=""
        muted
        autoplay
        :poster="videoBg"
        x-webkit-airplay="true"
        airplay="allow"
        webkit-playsinline="true"
        playsinline="true"
        src="https://rh.2hrh.com/static/file/rhportal/image/front/headernew/video1.mp4"
      />
      <div class="bannerbox">
        <h2 class="fadeInRight animated">最懂产业运营的工业服务商</h2>
        <div class="words fadeInUp animated">
          园区运营
          <span>联盟化</span>&nbsp;/&nbsp;&nbsp;工业管理
          <span>平台化</span>&nbsp;/&nbsp;&nbsp;产业发展
          <span>生态化</span>
        </div>
        <div class="videobtn fadeInUp animated" @click="show = true">
          <div class="imgBox"></div>
          了解融合科技
        </div>
      </div>
    </div>

    <!-- 主营业务 -->
    <div class="businesssystem">
      <div class="wp">
        <div class="tit-i">
          <h3>主营业务</h3>
          <h5>
            Main
            <span>Business</span>
          </h5>
        </div>
        <div class="businesss-card">
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/home/mb1.png" />
            </div>
            <div class="businesss-card-item-title">
              <span>数字科技</span>
            </div>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">数字科技</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                工业管理、园区运营、产业服务
              </div>
            </div>
          </div>
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/home/mb2.png" />
            </div>
            <span class="businesss-card-item-title">金融科技</span>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">金融科技</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                招商、安商、育商
              </div>
            </div>
          </div>
          <div class="businesss-card-item">
            <div class="businesss-card-item-img">
              <img src="../../assets/front/home/mb3.png" />
            </div>
            <span class="businesss-card-item-title">能源科技</span>
            <div class="businesss-card-item-mask">
              <div class="businesss-card-item-mask-title">能源科技</div>
              <div class="businesss-card-item-mask-bk"></div>
              <div class="businesss-card-item-mask-content">
                企业节能降耗、零碳园区、双碳达标
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 业务优势 -->
    <div class="advantages">
      <div class="wp">
        <div class="tit-i">
          <h3>业务优势</h3>
          <h5>
            Business
            <span>advantages</span>
          </h5>
        </div>
        <div class="advantages-box">
          <div
            class="advantages-box-item"
            @mousemove="show1 = false"
            @mouseleave="show1 = true"
          >
            <img
              v-if="show1"
              src="../../assets/front/home/aa.gif"
              width="154"
              height="177"
            />
            <img
              v-else
              src="../../assets/front/home/aaa.gif"
              width="154"
              height="177"
            />
            <div class="advantages-box-item-content">
              <span>百园互联</span>
              <div class="advantages-box-item-content-bk"></div>
              <div class="advantages-box-item-content-text">
                <p>百家园区互联服务体系</p>
                <p>省市县园共促营商环境</p>
              </div>
            </div>
          </div>
          <div
            class="advantages-box-item"
            @mousemove="show2 = false"
            @mouseleave="show2 = true"
          >
            <img
              v-if="show2"
              src="../../assets/front/home/ab.gif"
              width="154"
              height="177"
            />
            <img
              v-else
              src="../../assets/front/home/abb.gif"
              width="154"
              height="177"
            />
            <div class="advantages-box-item-content">
              <span>万企互动</span>
              <div class="advantages-box-item-content-bk"></div>
              <div class="advantages-box-item-content-text">
                <p>运营超百个产业集群</p>
                <p>对接超万户企业需求</p>
              </div>
            </div>
          </div>
          <div
            class="advantages-box-item"
            @mousemove="show3 = false"
            @mouseleave="show3 = true"
          >
            <img
              v-if="show3"
              src="../../assets/front/home/ac.gif"
              width="154"
              height="177"
            />
            <img
              v-else
              src="../../assets/front/home/acc.gif"
              width="154"
              height="177"
            />
            <div class="advantages-box-item-content">
              <span>资源互用</span>
              <div class="advantages-box-item-content-bk"></div>
              <div class="advantages-box-item-content-text">
                <p>已聚合海量优质服务资源</p>
                <p>可赋能百园万企升级转型</p>
              </div>
            </div>
          </div>
          <div
            class="advantages-box-item"
            @mousemove="show4 = false"
            @mouseleave="show4 = true"
          >
            <img
              v-if="show4"
              src="../../assets/front/home/ad.gif"
              width="154"
              height="177"
            />
            <img
              v-else
              src="../../assets/front/home/add.gif"
              width="154"
              height="177"
            />
            <div class="advantages-box-item-content">
              <span>全域互通</span>
              <div class="advantages-box-item-content-bk"></div>
              <div class="advantages-box-item-content-text">
                <p>依托工业大数据云平台</p>
                <p>拉动全域全国经济协同</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="cooperativeClient">
      <div class="wp">
        <div class="tit-i">
          <h3>合作伙伴</h3>
          <h5>
            Our
            <span>partners</span>
          </h5>
        </div>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide>
            <ul class="client-list clearfix">
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/2.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/8.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/1.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/qcc.png"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/14.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/zgdz.png"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/shcyhz.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/shzx.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/ylkj.png"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/jxjk.png"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </swiper-slide>
          <swiper-slide>
            <ul class="client-list clearfix">
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/7.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/3.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/4.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/5.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/6.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/9.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/10.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/11.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/12.jpg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <i class="t">&nbsp;</i><i class="r">&nbsp;</i
                ><i class="b">&nbsp;</i><i class="l">&nbsp;</i>
                <a href="javascript:;">
                  <img
                    src="../../assets/front/headernew/partners/15.jpg"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="cooperativeClient-tab">
          <div
            class="cooperativeClient-tab-item cooperativeClient-left"
            :class="{ 'cooperativeClient-tab-item-active': tab1 === 1 }"
            @click="tab1 = 1"
          ></div>
          <div
            class="cooperativeClient-tab-item cooperativeClient-right"
            :class="{ 'cooperativeClient-tab-item-active': tab1 === 2 }"
            @click="tab1 = 2"
          ></div>
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="row4 fix contactMain">
      <div class="wp">
        <div class="tit-i">
          <h3>联系我们</h3>
          <h5><span>contact</span> ronghe</h5>
        </div>
        <div class="contact-l">
          <ul class="ul-contact">
            <li class="li1">
              江西省南昌市红谷滩区南昌市九龙大道1388号VR产业基地三号楼6楼
            </li>
            <li class="li2">
              <span>0791-88553836 (主机)</span>
              <span>18179115029 (客服)</span>
            </li>
            <li class="li3">
              <span>3243452665@qq.com</span>
            </li>
          </ul>
          <div class="wxewm">
            <p>
              <img
                width="200px"
                height="200px"
                src="../../assets/front/headernew/ewm.jpg"
              />
              <span>扫一扫关注企业公众号</span>
            </p>
            <p>
              <img
                width="200px"
                height="200px"
                src="../../assets/front/headernew/yqym.png"
              />
              <span>扫一扫查看企业信息</span>
            </p>
          </div>
        </div>
        <div class="contact-r">
          <div id="map" class="mapbox"></div>
        </div>
      </div>
    </div>

    <!--    &lt;!&ndash; 产品体系 &ndash;&gt;-->
    <!--    <div class="productsystem">-->
    <!--      <div class="wp">-->
    <!--        <div class="tit-i">-->
    <!--          <h3>产品体系</h3>-->
    <!--          <h5>-->
    <!--            Product-->
    <!--            <span>System</span>-->
    <!--          </h5>-->
    <!--        </div>-->
    <!--        <div class="ps-wrap">-->
    <!--          <ul class="psw-list clearfix">-->
    <!--            <li @click="goPath(1)">-->
    <!--              <h3>-->
    <!--                <span>园区运营</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>打破园区“信息孤岛”</p>-->
    <!--                <p>扶优扶强心中有“数”</p>-->
    <!--                <p>全域园区协同运营</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(2)">-->
    <!--              <h3>-->
    <!--                <span>工业管理</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>工业家底一图全知晓</p>-->
    <!--                <p>工业运行状态全感知</p>-->
    <!--                <p>工业管理运筹无遗策</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(3)">-->
    <!--              <h3>-->
    <!--                <span>产业指导</span>-->
    <!--                <span>智慧云平台</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>产业现状看得清</p>-->
    <!--                <p>产业指导更科学</p>-->
    <!--                <p>产业发展能预见</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--            <li @click="goPath(4)">-->
    <!--              <h3>-->
    <!--                <span>企业服务</span>-->
    <!--                <span>（赣企服）</span>-->
    <!--              </h3>-->
    <!--              <div class="pswl-bottom">-->
    <!--                <p>大数据精准分析市场需求导向</p>-->
    <!--                <p>互联网收集整合生产配套资源</p>-->
    <!--                <p>物联网链接产能实现生产协同</p>-->
    <!--              </div>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--          <div class="psw-directions">-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--            <p>-->
    <!--              <img src="../../assets/front/headernew/updown.png" alt="" />-->
    <!--            </p>-->
    <!--          </div>-->
    <!--          <div class="psw-bottom">工业大数据应用平台</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    &lt;!&ndash; 公司组织架构 &ndash;&gt;-->
    <!--    <div class="organizationStructure">-->
    <!--      <div class="wp">-->
    <!--        <div class="tit-i">-->
    <!--          <h3>公司组织架构</h3>-->
    <!--          <h5>-->
    <!--            Organization-->
    <!--            <span>Structure</span>-->
    <!--          </h5>-->
    <!--        </div>-->
    <!--        <div class="os-bottom">-->
    <!--          <img-->
    <!--            style="max-width: 100%"-->
    <!--            src="../../assets/front/headernew/organization_icon.png"-->
    <!--            alt=""-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
// import Partners from './components/partners.vue';
// import ProductSystem from '../product/components/ProductSystem.vue';
import location from '../../assets/icon/location.png';
import videoBg from '../../assets/bg/videoBg.png';
// import orgBg from '../../assets/bg/orgWhite.png';
import orgBg from '../../assets/bg/org.png';
import { ImagePreview } from 'vant';

import zs1 from '../../assets/front/headernew/zs1.png';
import zs2 from '../../assets/front/headernew/zs2.png';
import as1 from '../../assets/front/headernew/as1.png';
import as2 from '../../assets/front/headernew/as2.png';
import ys1 from '../../assets/front/headernew/ys1.png';
export default {
  name: 'home-wrapper',
  // components: { Partners, ProductSystem },
  data() {
    return {
      show1: true,
      show2: true,
      show3: true,
      show4: true,
      swiperOption: {
        navigation: {
          nextEl: '.cooperativeClient-right',
          prevEl: '.cooperativeClient-left',
        },
      },
      tab1: 1,
      show: false,
      popShow: false,
      popList: [],
      popIndex: 0,
      pooArray: [
        [
          {
            title: '线上科技——井冈山产业招商地图',
            content:
              '井开区产业招商地图针对井开区主导产业，每个产业链节点分类不低于10个，平均每条产业链可获取目标企业数据不低于10000家。提供企业投资意向评估智能算法并按照相应算法动态给企业评估，自动绘制产业招商地图，每年提供精准更新招商项目库数量不低于20个。',
          },
          {
            title: '线下服务——助力东乡区人民政府招商引资',
            content:
              '融合科技为客户提供项目落地资金解决方案。目前融合科技助力东乡区招入三家企业：锻造轮毂、智慧教育、通航航空器，总投超过120亿，预计5年后年创税收超过10亿，第一年税收不低于1亿。',
          },
        ],
        [
          {
            title: '工业管理智慧云平台——为主管部门创新工业管理机制助力',
            content:
              '江西省工业智慧云平台建有全省一体化横向互联、纵向互通的智慧园区综合平台和省智慧工业大数据中心，是全国数据体量最大、服务体系最全、接入园区最多的省级智慧工业平台。',
          },
          {
            title: '企业服务平台——为工业企业转型升级服务模式赋能',
            content:
              '赣企服平台助力定南工业园区招工1500余人，促成华建英才人力资源公司与彩智电子科技有限公司长期合作招工协议。',
          },
        ],
        [
          {
            title: '亩均效益平台——优化产业区域布局',
            content:
              '亩均效益平台为工业高质量发展和工业考核提供数据支撑，为差别化资源配置和政策支撑提供方向，淘汰落后扶优扶强，打造优质可持续化营商环境。',
          },
        ],
      ],
      poplistArr: [
        [
          {
            imgSrc: zs1,
            title: '线上科技——井冈山产业招商地图',
            txt: '井开区产业招商地图针对井开区主导产业，每个产业链节点分类不低于10个，平均每条产业链可获取目标企业数据不低于10000家。提供企业投资意向评估智能算法并按照相应算法动态给企业评估，自动绘制产业招商地图，每年提供精准更新招商项目库数量不低于20个。',
          },
          {
            imgSrc: zs2,
            title: '线下服务——助力东乡区人民政府招商引资',
            txt: '融合科技为客户提供项目落地资金解决方案。目前融合科技助力东乡区招入三家企业：锻造轮毂、智慧教育、通航航空器，总投超过120亿，预计5年后年创税收超过10亿，第一年税收不低于1亿。',
          },
        ],
        [
          {
            imgSrc: as1,
            title: '工业管理智慧云平台——为主管部门创新工业管理机制助力',
            txt: '江西省工业智慧云平台建有全省一体化横向互联、纵向互通的智慧园区综合平台和省智慧工业大数据中心，是全国数据体量最大、服务体系最全、接入园区最多的省级智慧工业平台。',
          },
          {
            imgSrc: as2,
            title: '企业服务平台——为工业企业转型升级服务模式赋能',
            txt: '赣企服平台助力定南工业园区招工1500余人，促成华建英才人力资源公司与彩智电子科技有限公司长期合作招工协议。',
          },
        ],
        [
          {
            imgSrc: ys1,
            title: '亩均效益平台——优化产业区域布局',
            txt: '亩均效益平台为工业高质量发展和工业考核提供数据支撑，为差别化资源配置和政策支撑提供方向，淘汰落后扶优扶强，打造优质可持续化营商环境。',
          },
        ],
      ],
      videoBg,
      linkMessages: [
        '电话：0791-88553836',
        '地址：江西省南昌市丰和中大道912号地铁大厦13A',
        '客服：18179115029',
        '邮箱：3243452665@qq.com',
      ],
      serviceList: [
        {
          title: '优化产业布局',
          simpleTitle: '招',
          descripts: [
            '线上科技：提供产业链招商大数据服务',
            '线下金融：提供项目落地资金解决方案',
          ],
          bottomText: '线上线下双循环全面发力',
        },
        {
          title: '强化产业服务',
          simpleTitle: '安',
          descripts: [
            '为主管部门创新工业管理机制助力',
            '为工业企业转型升级服务模式赋能',
          ],
          bottomText: '企业经纪人提供量身服务',
        },
        {
          title: '深化产业培育',
          simpleTitle: '育',
          descripts: [
            '数据挖掘，招引培育服务型制造新业态',
            '躬身入局，投资参股两化融合新型企业',
          ],
          bottomText: '推动工业转型升级由服务到培育',
        },
      ],
    };
  },
  methods: {
    goPath(index) {
      if (index == 1) {
        window.open('https://www.jx968969.com/XOffice/login/province/yqyylist');
      } else if (index == 2) {
        window.open('https://www.jx968969.com/XOffice/login/province/gygllist');
      } else if (index == 3) {
        window.open('https://www.jx968969.com/XOffice/login/province/cyzdlist');
      } else if (index == 4) {
        window.open('https://www.jx968969.com/springBoot/site/360000');
      }
    },
    pauseVideo() {
      this.$refs.video.pause();
    },
    startViedo() {
      this.$refs.video.play();
    },
    preview() {
      ImagePreview([orgBg]);
    },
    businessPop(e) {
      this.popIndex = e;
      this.popShow = true;
      this.popList = this.poplistArr[e];
    },
  },
  mounted() {
    // 加载图片
    const AMap = window.AMap;
    const map = new AMap.Map('map', {
      resizeEnable: false,
      center: [115.798318, 28.607637],
      zooms: [14, 18],
      zoom: 15.8,
    });
    const marker = new AMap.Marker({
      position: new AMap.LngLat(115.798318, 28.607637),
      content: `<div class="company-location">
        <img src="${location}" alt="" width="40px" height="40px">
<!--        <div class="text">江西融合科技</div>-->
<!--        <div class="text">有限责任公司</div>-->
      </div>`,
      offset: new AMap.Pixel(-20, -40),
    });
    map.add(marker);
    var markerArr = {
      title: '江西融合科技有限责任公司',
      content: '江西省南昌市红谷滩区南昌市九龙大道1388号VR产业基地三号楼6楼',
      point: '115.798318, 28.607637',
      isOpen: 1,
      icon: { w: 23, h: 25, l: 46, t: 21, x: 9, lb: 12 },
    };
    var infoWindow = new AMap.InfoWindow({
      content:
        "<b class='iw_poi_title' title='" +
        markerArr.title +
        "'>" +
        markerArr.title +
        "</b><div class='iw_poi_content'>" +
        markerArr.content +
        '</div>',
      offset: new AMap.Pixel(10, -40),
    });

    infoWindow.open(map, new AMap.LngLat(115.798318, 28.607637));
    function showInfoM() {
      infoWindow.open(map, new AMap.LngLat(115.798318, 28.607637));
    }
    marker.on('click', showInfoM);
    document.addEventListener(
      'WeixinJSBridgeReady',
      function () {
        this.$refs.bgVideo.play();
      },
      false,
    );
  },
};
</script>
<style lang="scss" scoped>
/deep/ .amap-logo {
  display: none;
  visibility: hidden;
}
/deep/ .amap-copyright {
  display: none;
  visibility: hidden !important;
}
.home-wrapper {
  /deep/ .van-popup.video-popup {
    background: none;
  }
  .headerbox {
    width: 100%;
    height: 750px;
    overflow: hidden;
    background-color: #777;
    position: relative;
  }
  .headerbox video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .headerbox .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .bannerbox {
    position: absolute;
    top: 90px;
    width: 100%;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
  .bannerbox h2 {
    font: bold 60px/80px 'microsoft yahei';
    color: #fff;
    letter-spacing: 10px;
    margin: 0;
  }
  .bannerbox .words {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    font: 22px/50px 'microsoft yahei';
    color: #fff;
    margin-top: 20px;
    letter-spacing: 3px;
  }
  .bannerbox .words span {
    color: #fff000;
  }
  .bannerbox .videobtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    width: 220px;
    height: 44px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    color: #fff;
    font: 20px/44px 'microsoft yahei';
    margin-top: 80px;
    letter-spacing: 3px;
    cursor: pointer;
    .imgBox {
      height: 22px;
      width: 22px;
      margin-right: 10px;
      background: url('../../assets/front/headernew/videobtn.png');
    }
  }
  .bannerbox .videobtn:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    width: 220px;
    height: 44px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    color: #333333;
    background: #ffffff;
    font: 20px/44px 'microsoft yahei';
    margin-top: 80px;
    letter-spacing: 3px;
    cursor: pointer;
    .imgBox {
      height: 22px;
      width: 22px;
      margin-right: 10px;
      background: url('../../assets/front/headernew/videobtn_black.png');
      background-size: 100% 100%;
    }
  }
  .bannerbox .videobtn img {
    margin-right: 10px;
  }
  .videopop {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    transition: all 0.3s;
  }
  .videopop .videwrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .videopop .videoinner {
    position: absolute;
    width: 85%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
  }
  .videopop .videoinner span {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    font: 20px/40px 'microsoft yahei';
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
  }
  .videopop .videoinner video {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: fill;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }

  .businesssystem .tit-i {
    padding-bottom: 20px;
  }
  .businesslist {
    padding-bottom: 80px;
  }
  .businesslist li {
    width: 326px;
    float: left;
    margin-right: 96px;
  }
  .businesslist li:last-of-type {
    margin-right: 0;
  }
  .businesslist .business-top {
    position: relative;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }
  .businesslist .business-top .line {
    position: absolute;
    top: 57%;
    left: 236px;
    width: 273px;
    height: 13px;
    background-color: #f1f1f1;
  }
  .businesslist .business-top .ost-inner {
    position: relative;
    width: 180px;
    height: 180px;
  }
  .businesslist .business-top .ostinner {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    animation: living 2s linear infinite;
  }
  @keyframes living {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  .businesslist .business-top .ostinner1 {
    margin-left: -60px;
    margin-top: -60px;
    width: 120px;
    height: 120px;
  }
  .businesslist .business-top .ostinner2 {
    margin-left: -56px;
    margin-top: -56px;
    width: 112px;
    height: 112px;
  }
  .businesslist .business-top .ostinner3 {
    margin-left: -52px;
    margin-top: -52px;
    width: 104px;
    height: 104px;
  }
  .businesslist .business-top .ostinner4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 50%;
    font-size: 60px;
    color: #fff;
    font-family: 'FZCuHeiSongS-B-GB';
    cursor: pointer;
    transition: 0.2s;
  }
  .businesslist li:nth-of-type(1) .business-top .ostinner {
    border-color: rgba(39, 159, 255, 0.8);
  }
  .businesslist li:nth-of-type(2) .business-top .ostinner {
    border-color: rgba(255, 149, 46, 0.8);
  }
  .businesslist li:nth-of-type(3) .business-top .ostinner {
    border-color: rgba(255, 75, 96, 0.8);
  }
  .businesslist li:nth-of-type(1) .business-top .ostinner4 {
    background: linear-gradient(0deg, #8acbff, #1f9cff);
  }
  .businesslist li:nth-of-type(2) .business-top .ostinner4 {
    background: linear-gradient(0deg, #ffbb79, #ff9229);
  }
  .businesslist li:nth-of-type(3) .business-top .ostinner4 {
    background: linear-gradient(0deg, #ff8695, #ff3f56);
  }
  .businesslist .business-title {
    font: 37px/60px 'FZCuHeiSongS-B-GB';
    text-align: center;
    padding: 20px 0 15px;
  }
  .businesslist li:nth-of-type(1) .business-title {
    color: #249eff;
  }
  .businesslist li:nth-of-type(2) .business-title {
    color: #ff952e;
  }
  .businesslist li:nth-of-type(3) .business-title {
    color: #ff445a;
  }
  .businesslist li:nth-of-type(1) .topwrap:hover .ostinner4 {
    box-shadow: 0px 0 15px 0px rgba(31, 156, 255, 0.9);
  }
  .businesslist li:nth-of-type(2) .topwrap:hover .ostinner4 {
    box-shadow: 0px 0 15px 0px rgba(255, 126, 55, 0.9);
  }
  .businesslist li:nth-of-type(3) .topwrap:hover .ostinner4 {
    box-shadow: 0px 0 15px 0px rgba(255, 63, 86, 0.9);
  }
  .businesslist li:nth-of-type(1) .topwrap:hover .business-title {
    text-shadow: 5px 5px 5px rgba(31, 156, 255, 0.6);
  }
  .businesslist li:nth-of-type(2) .topwrap:hover .business-title {
    text-shadow: 5px 5px 5px rgba(255, 126, 55, 0.6);
  }
  .businesslist li:nth-of-type(3) .topwrap:hover .business-title {
    text-shadow: 5px 5px 5px rgba(255, 63, 86, 0.6);
  }
  .businesslist .business-list {
    height: 114px;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    //padding: 25px 19px;
    text-align: center;
  }
  .businesslist .business-list p {
    font: 16px/20px 'microsoft yahei';
    color: #323333;
    text-align: center;
  }
  .businesslist .business-arrows {
    text-align: center;
    margin: 23px 0;
  }
  .businesslist .business-arrows img {
    vertical-align: middle;
  }
  .businesslist .business-bottom {
    font: 600 20px/20px 'Source Han Sans CN';
    text-align: center;
    margin-bottom: 30px;
  }
  .businesslist li:nth-of-type(1) .business-bottom {
    color: #249eff;
  }
  .businesslist li:nth-of-type(2) .business-bottom {
    color: #ff952e;
  }
  .businesslist li:nth-of-type(3) .business-bottom {
    color: #ff445a;
  }
  .productsystem {
    width: 100%;
    background: url(../../assets/front/headernew/product_bg.png) center center
      no-repeat;
    background-size: 100% 100%;
    padding-bottom: 50px;
  }
  .productsystem .psd-titles {
    position: absolute;
    top: 10px;
    left: 0;
  }
  .productsystem .psd-titles p {
    width: 268px;
    height: 76px;
    float: left;
    margin-right: 21px;
    font: 22px/25px 'PingFang SC';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .productsystem .psd-titles p:last-of-type {
    margin-right: 0;
  }
  .productsystem .ps-content {
    position: relative;
    margin-top: -20px;
  }
  .productsystem .ps-direction {
    overflow: hidden;
    position: relative;
    height: 278px;
  }
  .productsystem .ps-direction img {
    width: 322px;
    height: 291px;
    position: absolute;
    top: 0;
    left: -12px;
  }
  /* .productsystem .ps-direction img:hover{ box-shadow: 0px 4px 15px 0px rgba(31, 156, 255, .34);} */
  .productsystem .ps-direction img:nth-of-type(2) {
    left: 279px;
  }
  .productsystem .ps-direction img:nth-of-type(3) {
    left: 569px;
  }
  .productsystem .ps-direction img:nth-of-type(4) {
    left: 856px;
  }
  .productsystem .ps-list {
    position: absolute;
    top: 86px;
    left: 0;
  }
  .productsystem .ps-list li {
    float: left;
    width: 268px;
    margin-right: 21px;
    cursor: pointer;
  }
  .productsystem .ps-list li:last-of-type {
    margin-right: 0;
  }
  .productsystem .ps-list li .list-inner {
    width: 100%;
    height: 192px;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
  }
  .productsystem .ps-list li .list-inner p {
    font: 16px/42px 'PingFang SC';
    color: #666;
  }
  .productsystem .ps-list li .list-down {
    position: relative;
    width: 10px;
    height: 25px;
    background-color: #fff;
    background-color: #d0e4ff;
    margin: auto;
    overflow: hidden;
  }
  .productsystem .ps-list li .list-down img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .productsystem .ps-list li .list-down img:nth-of-type(1) {
    transform: translateY(-19px);
    animation: updown 1s linear infinite;
  }
  .productsystem .ps-bottom {
    font: 26px/60px 'PingFang SC';
    color: #fff;
    width: 1136px;
    height: 60px;
    text-align: center;
    background-color: #0068db;
    margin-top: 25px;
  }
  @keyframes updown {
    0% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(25px);
    }
  }
  .productsystem .productlist {
  }
  .productsystem .productlist li {
    width: 243px;
    height: 300px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    transition: all 0.2s;
    float: left;
    margin-right: 65px;
  }
  .productsystem .productlist li:last-of-type {
    margin-right: 0;
  }
  .productsystem .productlist li:hover {
    transform: translateY(-20px);
    transition: all 0.5s;
  }
  .productsystem .productlist li a {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    height: 100%;
  }
  .productsystem .productlist li .pl-top {
    width: 100%;
    height: 140px;
    border-bottom: 1px solid #e2e3e8;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
  }
  .productsystem .productlist li .pl-top img {
    display: block;
    width: 50px;
    height: 50px;
  }
  .productsystem .productlist li .pl-top p {
    font: 20px/30px 'microsoft yahei';
    color: #333;
    margin-top: 5px;
  }
  .productsystem .productlist li .pl-bottom {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
  }
  .productsystem .productlist li .pl-bottom span {
    font: 14px/20px 'microsoft yahei';
    color: #666;
  }
  .productsystem .productlist li .pl-bottom span:nth-of-type(2),
  .productsystem .productlist li .pl-bottom span:nth-of-type(3) {
    margin-top: 13px;
  }

  .productsystem .ps-wrap .psw-list {
  }
  .productsystem .ps-wrap .psw-list li {
    float: left;
    width: 23.5%;
    height: 280px;
    background-color: #4444;
    margin-right: 2%;
    cursor: pointer;
  }
  .productsystem .ps-wrap .psw-list li:hover {
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.7);
  }
  .productsystem .ps-wrap .psw-list li h3 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    height: 80px;
    background-color: #c00000;
  }
  .productsystem .ps-wrap .psw-list li h3 span {
    display: block;
    color: #fff;
    font: 20px/30px 'PingFang SC';
  }
  .productsystem .ps-wrap .psw-list li:last-of-type {
    margin-right: 0;
  }
  .productsystem .ps-wrap .psw-list li .pswl-bottom {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    height: 200px;
    background-color: #0070c0;
  }
  .productsystem .ps-wrap .psw-list li .pswl-bottom p {
    font: 16px/42px 'PingFang SC';
    color: #fff;
    margin: 0;
  }
  .productsystem .psw-directions {
  }
  .productsystem .psw-directions p {
    margin: 0;
    float: left;
    width: 23.5%;
    height: 33px;
    margin-right: 2%;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
  }
  .productsystem .psw-directions p:last-of-type {
    margin-right: 0;
  }
  .productsystem .psw-bottom {
    font: 26px/60px 'PingFang SC';
    display: flex;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 60px;
    text-align: center;
    background-color: #002060;
    margin-top: 33px;
  }
  .popbox {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
  }
  .popbox .popwrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popbox .popinner {
    position: absolute;
    width: 50%;
    max-height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
  }
  .popbox .popinner .popclose {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    font: 20px/40px 'microsoft yahei';
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
  }
  .popbox .popContent {
    width: 100%;
    max-height: 80vh;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .popbox .popContent .pc-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .popbox .popContent .pc-title H3 {
    font: 28px/35px 'FZCuHeiSongS-B-GB';
  }
  .popbox .popContent .pc-title p {
    font: 13px/20px 'PingFang SC';
    color: #999;
  }
  .popbox .popContent::-webkit-scrollbar {
    width: 12px;
    height: 1px;
  }
  .popbox .popContent::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    background: #999;
  }
  .popbox .popContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
  .popbox .popContent li {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;
    margin-bottom: 25px;
  }
  .popbox .popContent li:last-of-type {
    margin-bottom: 0;
  }
  .popbox .popContent li img {
    width: 272px;
    height: 175px;
  }
  .popbox .popContent li .content-right {
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
    flex: 1;
    padding-left: 20px;
  }
  .popbox .popContent li .content-right h3 {
    font: 600 19px/30px 'PingFang SC';
    color: #333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .popbox .popContent li .content-right p {
    font: 15px/27px 'PingFang SC';
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }
  .popbox .popinner {
    width: 900px;
  }

  .popbox .popContent {
    max-height: 600px;
    padding-bottom: 40px;
  }

  .popbox .popContent .pc-title {
    padding-top: 10px;
  }

  .popbox .popContent ul {
    padding: 0 20px;
  }
  .popbox .popContent p {
    margin: 0;
  }

  .organizationStructure {
    height: auto;
    background: url(../../assets/front/headernew/organization_bg.png) center
      center no-repeat;
    background-size: 100% 100%;
    padding-bottom: 30px;
  }
  .organizationStructure .os-top {
    height: 180px;
  }
  .organizationStructure .os-top .ost-inner {
    margin-left: 494px;
    position: relative;
    width: 180px;
    height: 180px;
  }
  .organizationStructure .os-top .ostinner {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    animation: living 2s linear infinite;
  }
  .organizationStructure .os-top .ostinner1 {
    margin-left: -65px;
    margin-top: -65px;
    width: 130px;
    height: 130px;
  }
  .organizationStructure .os-top .ostinner2 {
    margin-left: -60px;
    margin-top: -60px;
    width: 120px;
    height: 120px;
  }
  .organizationStructure .os-top .ostinner3 {
    margin-left: -55px;
    margin-top: -55px;
    width: 110px;
    height: 110px;
  }
  .organizationStructure .os-top .ostinner4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: 50%;
    background: #ff8948;
    box-shadow: 0px 8px 12px 0px rgba(255, 126, 55, 0.34);
    font-size: 24px;
    color: #fff;
  }
  .organizationStructure .os-bottom {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
  }
  .cooperativeClient .client-list {
    padding: 0 0px 20px;
  }
  .cooperativeClient .client-list li {
    position: relative;
    float: left;
    width: 220px;
    height: 120px;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 17.5px;
    transition: all 0.2s;
    margin-bottom: 25px;
  }
  .cooperativeClient .client-list li:nth-of-type(5n) {
    margin-right: 0;
  }
  .cooperativeClient .client-list li a {
    display: block;
    line-height: 120px;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #ffffff;
  }
  .cooperativeClient .client-list li a img {
    display: inline-block;
    width: 140px;
    height: 60px;
    vertical-align: middle;
  }
  .cooperativeClient .client-list li:hover {
    //box-shadow: 0px 2px 6px 0px rgba(58, 151, 255, 0.34);
  }

  .cooperativeClient .client-list li i {
    display: block;
    position: absolute;
    overflow: hidden;
    background-image: url(../../assets/front/home/border.svg);
    background-repeat: no-repeat;
    transition: all 0.5s;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .cooperativeClient .client-list li i.t {
    width: 0;
    height: 0;
    overflow: hidden;
    background-position: 0 top;
    background-size: 100%;
    top: 0;
    left: 0;
  }

  .cooperativeClient .client-list li:hover i.t {
    width: 100%;
    height: 3px;
  }

  .cooperativeClient .client-list li i.r {
    width: 0;
    height: 0;
    background-position: right 0;
    background-size: auto 100%;
    right: 0;
    top: 0;
  }

  .cooperativeClient .client-list li:hover i.r {
    height: 100%;
    width: 3px;
  }

  .cooperativeClient .client-list li i.b {
    width: 0;
    height: 0;
    background-position: 0 bottom;
    background-size: 100%;
    bottom: 0;
    right: 0;
  }

  .cooperativeClient .client-list li:hover i.b {
    width: 100%;
    height: 3px;
  }

  .cooperativeClient .client-list li i.l {
    width: 0;
    height: 0;
    background-position: left 0;
    background-size: auto 100%;
    left: 0;
    bottom: 0;
  }

  .cooperativeClient .client-list li:hover i.l {
    height: 100%;
    width: 3px;
  }

  .cooperativeClient .client-list li:hover i {
    opacity: 1;
  }

  .clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  } /*伪元素是行内元素 正常浏览器清除浮动方法*/
  .clearfix {
    *zoom: 1;
  } /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
  .contact-l {
    width: 38%;
    float: left;
  }
  .contact-r {
    width: 62%;
    float: right;
  }
  .ul-contact li {
    background: url(../../assets/front/i_icon2.png) no-repeat;
    padding-left: 60px;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 35px;
  }
  .ul-contact li.li1 {
    background-position: 0 0;
  }
  .ul-contact li.li2 {
    background-position: 0 -74px;
  }
  .ul-contact li.li3 {
    background-position: 0 -144px;
  }
  .contactMain {
    padding-bottom: 50px;
    height: 613px;
  }
  .contact-l .wxewm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
  }
  .contact-l .wxewm p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
  .contact-l .wxewm img {
    width: 200px;
    height: 200px;
  }
  .contact-l .wxewm span {
    display: block;
    font: 16px/16px 'microsoft yahei';
    color: #333;
  }
  .contact-r {
    width: 58%;
    height: 350px;
  }
  .contact-r .mapbox {
    width: 100%;
    height: 100%;
  }

  .contact-form .inp {
    width: 49%;
    border: 1px solid #6d6d88;
    height: 33px;
    padding: 0 10px;
    margin-bottom: 15px;
  }
  .contact-form textarea {
    width: 100%;
    border: 1px solid #6d6d88;
    height: 98px;
    margin-bottom: 24px;
  }
  .contact-form .sub {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background-color: #63637f;
    border: 0;
    cursor: pointer;
    font-family: '΢���ź�', 'Microsoft Yahei';
    font-size: 16px;
  }

  .map {
    margin-top: 100px;
    position: relative;
  }
  .map em {
    background: url(../../assets/front/arr1.jpg) center top no-repeat;
    width: 100%;
    height: 15px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  .map-s {
    background: url(../../assets/front/map_s.png) center top;
    height: 107px;
    text-align: center;
    overflow: hidden;
  }
  .map .btn {
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 132px;
    display: inline-block;
  }
  .map .btn-down {
    background: url(../../assets/front/map_down.png) no-repeat;
    width: 112px;
    height: 48px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 52px;
    margin-left: -50px;
    z-index: 99;
  }
  #map {
  }
  .map-pop {
    display: none;
    height: 540px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .map .map-bg1,
  .map .map-bg2 {
    background: url(../../assets/front/ban_bg_top.png) no-repeat center 0;
    height: 54px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 66;
  }
  .map .map-bg2 {
    background-image: url(../../assets/front/map_bg2.png);
    height: 41px;
    bottom: 0px;
    top: auto;
  }
  .map-pop-big {
    height: 600px;
    bottom: auto;
    top: 0;
  }
  .map-big {
    height: 600px;
    margin-top: 0;
  }
}
.businesss-card {
  display: flex;
  flex-direction: row;
}

.businesss-card-item {
  position: relative;
  cursor: pointer;
  img {
    height: 230px;
  }
  .businesss-card-item-title {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 26px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
}

.businesss-card-item-img {
  height: 230px;
  overflow: hidden;
}

.businesss-card-item-mask {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #0045ce;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .businesss-card-item-mask-title {
    font-size: 30px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
  }
  .businesss-card-item-mask-bk {
    height: 2px;
    width: 20px;
    background: #ffffff;
    margin: 16px 0;
  }
  .businesss-card-item-mask-content {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.businesss-card-item:hover {
  .businesss-card-item-title {
    display: none;
  }
  .businesss-card-item-mask {
    animation: show 1s linear;
    opacity: 0.8;
  }
  img {
    transform: scale(1.2);
    transition: all 1s;
  }
}
.businesss-card-item:nth-of-type(2) {
  margin: 0 20px;
}

.advantages-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.advantages-box-item {
  display: flex;
  flex-direction: row;
  padding: 50px 60px;
}

.advantages-box-item:nth-of-type(1) {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
.advantages-box-item:nth-of-type(2) {
  border-bottom: 1px solid #ebebeb;
}
.advantages-box-item:nth-of-type(3) {
  border-right: 1px solid #ebebeb;
}
.advantages-box-item-content {
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  span {
    font-size: 24px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #1f73ff;
  }
  .advantages-box-item-content-bk {
    height: 2px;
    width: 40px;
    background: #1f73ff;
    margin: 30px 0;
  }
  .advantages-box-item-content-text {
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}

.cooperativeClient-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .cooperativeClient-tab-item {
    cursor: pointer;
    height: 4px;
    width: 40px;
    background: #1f73ff;
    opacity: 0.2;
    margin-right: 20px;
  }
  .cooperativeClient-tab-item-active {
    opacity: 1;
  }
}
.cooperativeClient {
  background: #f6f8fb;
  padding-bottom: 60px;
}
</style>
